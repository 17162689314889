import React from "react";
import Layout from "../components/layout";
import Container from "../components/container";
import Title from "../components/title";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import Helmet from "react-helmet";
export default function Login() {
  return (
    <Layout>
      <Helmet
        script={[
          {
            type: `text/javascript`,
            innerHTML: `window.__L_SETUP_ID__ = '59fc4644-2d79-11ea-9066-b3928c9efb30'; window.__L_SETUP_API__ = 'https://api.lenus.io/v1';`
          },
          {
            type: `text/javascript`,
            src: `https://lenus.io/tracker.js`
          }
        ]}
      />
      <Container>
        <div data-lns-mountpoint=""></div>
      </Container>
    </Layout>
  );
}
